import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './navbar';
import reportWebVitals from './reportWebVitals';
import Sidebar from './sidebar';
import Footer from './footer';
import Vthmese from './VThmese';
import $ from 'jquery';


if (process.env.NODE_ENV === "production")
  console.log = function no_console() { };





// const sidebar = ReactDOM.createRoot(document.getElementById('sidebar'));
// sidebar.render(
//   <>
//     < Navbar />
//   </>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);

// const navbar = ReactDOM.createRoot(document.getElementById('navbar'));
// navbar.render(
//   <>
//     <Sidebar />
//   </>
// );

// const footer = ReactDOM.createRoot(document.getElementById('footer'));
// footer.render(
//   <>
//     <Footer />
//   </>
// );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
