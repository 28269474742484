import React, { Component } from 'react';
//import WheelComponent from "react-wheel-of-prizes";
import WheelComponent from "./WheelComponent";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import jwt_decode from "jwt-decode";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            lock: "5 บาท",
            point: 0,
            user_id: "",
            disable: false,
            segColors: null,
            segments: null
            // segments: [{ id: 1, name: '5 บาท' }, { id: 2, name: '100 บาท' }, { id: 3, name: '300 บาท' }, { id: 4, name: '10 บาท' }, { id: 5, name: '20 บาท' }, { id: 6, name: 'คร้งหน้านะ บาท' }, { id: 7, name: 'เกือบได้แล้ว' }, { id: 8, name: 'ครั้งหน้านะ' }, { id: 9, name: '1000 บาท' }],
        }

    }
    componentDidMount() {
        this.checklogin();
        this.getwheel();
    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                point: decoded.message.Point,
                disable: true,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    getwheel = async () => {
        // var segments = [{ id: 1, name: '5 บาท' }, { id: 2, name: '100 บาท' }, { id: 3, name: '300 บาท' }, { id: 4, name: '10 บาท' }, { id: 5, name: '20 บาท' }, { id: 6, name: 'คร้งหน้านะ บาท' }, { id: 7, name: 'เกือบได้แล้ว' }, { id: 8, name: 'ครั้งหน้านะ' }, { id: 9, name: '1000 บาท' }];
        try {
            await instance.post("/api/v1/getwheelspin", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    var segments = []
                    var segColors = []
                    for (var i = 0; i < datas.length; i++) {
                        var id = datas[i].id
                        var name = datas[i].description
                        var color = datas[i].color
                        var setp = { id, name }
                        segments.push(setp)
                        segColors.push(color)
                    }
                    // console.log(segColors)
                    this.setState({
                        segments: segments,
                        segColors: segColors,
                    });
                    //   console.log(61, segments)


                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (error) {
            console.log(error)
        }



    }

    onFinished = async (winner) => {
        //  console.log(winner);
        this.checklogin();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            try {
                var row = {
                    wheelID: winner.id,
                    userid: Username,
                }
                await instance.post("/api/v1/postwheelspin", {
                    System: Systems,
                    userid: Username,
                    type: 1,
                    rows: row,
                }).then((res) => {
                    if (res.data.status === 200) {
                        const datas = res.data.message;
                    }
                    else {

                    }
                });
            }
            catch (error) {
                console.log(error)
            }
        }

    };
    render() {
        return (
            <>
                <div
                    className="x-modal modal -hello-popup spinwheel show"
                    id="wheelgiftspin"
                    tabIndex="{-1}"
                    role="dialog"
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -no-fixed-button"
                        role="document"
                    >
                        <div className="modal-content -modal-content">
                            <div className="-inner-heading-wrapper ">
                                <div className="row">
                                    <div className="col-md-4 -img-box" data-animatable="fadeInUp" >
                                        <img
                                            className="-logo"
                                            src="/build/web/igame-index-lobby-wm/img/logo.png"
                                            alt="Casino slot online logo"
                                            width={250}
                                            height={100}
                                        />
                                    </div>
                                    <div className="col-md-8 -content-container" style={{ color: "white" }}>
                                        <h1 className="x-hidden-heading" >
                                            กงล้อมหาสนุก
                                        </h1>
                                        <h2 className="-title js-excited">
                                            <div className="d-none d-md-block" style={{ fontSize: "1.3rem" }}>
                                                <div className="js-item x-text-excited-fade-out x-text-excited-fade-in">
                                                    เติม 500 ได้สิทธิ์หมุนฟรี 1 สิทธิ์
                                                </div>
                                            </div>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body -modal-body" >
                                <div className="js-modal-content">
                                    <div className="x-promotion-hello-modal-body"  >
                                        {(this.state.segments === null || this.state.segColors === null) ? "" :
                                            <div className="x-promotion-hello-modal-body">
                                                <WheelComponent
                                                    segments={this.state.segments}
                                                    segColors={this.state.segColors}
                                                    winningSegment={this.state.lock}
                                                    userid={this.state.user_id}
                                                    disablex={this.state.disable}
                                                    onFinished={(winner) => this.onFinished(winner)}
                                                    primaryColor="black"
                                                    contrastColor="white"
                                                    round={this.state.point}
                                                    buttonText="หมุน"
                                                    upDuration={500}
                                                    downDuration={600}
                                                    fontFamily="FC Iconic Text, Helvetica Neue, Helvetica, Arial, sans-serif"
                                                />
                                                <div className='wheelspinsx' style={{ marginLeft: "4%", position: "absolute !important", marginTop: "-560px", width: "650px", height: "450px", backgroundImage: "url(/build/web/igame-index-lobby-wm/img/newbox.png)" }} >
                                                </div>
                                            </div>

                                        }


                                    </div>
                                </div>
                                {/* <div className="js-modal-content" >
                                    <div className="-inner-heading-wrapper ">
                                        <div className="row">
                                            <div className="col-md-4 -img-box" data-animatable="fadeInUp" >
                                                <img
                                                    className="-logo"
                                                    src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                    alt="Casino slot online logo"
                                                    width={250}
                                                    height={100}
                                                />
                                            </div>
                                            <div className="col-md-8 -content-container" style={{ color: "white" }}>
                                                <h1 className="x-hidden-heading" >
                                                    กงล้อมหาสนุก
                                                </h1>
                                                <h2 className="-title js-excited">
                                                    <div className="d-none d-md-block" style={{ fontSize: "1.3rem" }}>
                                                        <div className="js-item x-text-excited-fade-out x-text-excited-fade-in">
                                                            เติม 500 ได้สิทธิ์หมุนฟรี 1 สิทธิ์
                                                        </div>
                                                    </div>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="js-modal-content" style={{ alignItems: "center", textAlign: "center" }}>
                                    <div className='wheelspins'  >
                                        {(this.state.segments === null || this.state.segColors === null) ? "" :
                                            <div className="x-promotion-hello-modal-body">
                                                <WheelComponent
                                                    segments={this.state.segments}
                                                    segColors={this.state.segColors}
                                                    winningSegment={this.state.lock}
                                                    userid={this.state.user_id}
                                                    disablex={this.state.disable}
                                                    onFinished={(winner) => this.onFinished(winner)}
                                                    primaryColor="black"
                                                    contrastColor="white"
                                                    round={this.state.point}
                                                    buttonText="หมุน"
                                                    upDuration={500}
                                                    downDuration={600}
                                                    fontFamily="FC Iconic Text, Helvetica Neue, Helvetica, Arial, sans-serif"
                                                />
                                                <div className='wheelspinsx' style={{ position: "absolute !important", marginTop: "-500px", height: "55px", backgroundImage: "url(/build/web/igame-index-lobby-wm/img/notification-title-bg.png)" }} >
                                                </div>
                                            </div>

                                        }

                                    </div>

                                </div> */}

                            </div>

                        </div>
                    </div>
                </div >
            </>



        );
    }
}
export default App;
